import { LeakageCauseEquipmentDetail, LeakageCauseCommonAreaDetail, LeakageCauseOutsideHouseDetail, QualificationRoomType, QualificationEquipmentType, } from '@shared/types/api/claim';
export const QUALIFICATION_EQUIPMENT = {
    [QualificationEquipmentType.Pipe]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.pipe',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.pipe',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.pipe.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.pipe',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.pipe',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.pipe',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.pipe',
    },
    [QualificationEquipmentType.WashBasin]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.wash_basin',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.wash_basin',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.wash_basin.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wash_basin',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wash_basin',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wash_basin',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wash_basin',
    },
    [QualificationEquipmentType.Sink]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.sink',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.sink',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.sink.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.sink',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.sink',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.sink',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.sink',
    },
    [QualificationEquipmentType.Shower]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.shower',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.shower',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.shower.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.shower',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.shower',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.shower',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.shower',
    },
    [QualificationEquipmentType.Bathtube]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.bathtube',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.bathtube',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.bathtube.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.bathtube',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.bathtube',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.bathtube',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.bathtube',
    },
    [QualificationEquipmentType.WC]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.wc',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.wc',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.wc.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wc',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wc',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wc',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wc',
    },
    [QualificationEquipmentType.Dishwasher]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.dishwasher',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.dishwasher',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.dishwasher.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.dishwasher',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.dishwasher',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.dishwasher',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.dishwasher',
    },
    [QualificationEquipmentType.Refrigerator]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.refrigerator',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.refrigerator',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.refrigerator.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.refrigerator',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.refrigerator',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.refrigerator',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.refrigerator',
    },
    [QualificationEquipmentType.WashingMachine]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.washing_machine',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.washing_machine',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.washing_machine.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.washing_machine',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.washing_machine',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.washing_machine',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.washing_machine',
    },
    [QualificationEquipmentType.Radiator]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.radiator',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.radiator',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.radiator.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.radiator',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.radiator',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.radiator',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.radiator',
    },
    [QualificationEquipmentType.Boiler]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.boiler',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.boiler',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.boiler.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.boiler',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.boiler',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.boiler',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.boiler',
    },
    [QualificationEquipmentType.HotWaterTank]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.hot_water_tank',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.hot_water_tank',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.hot_water_tank.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.hot_water_tank',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.hot_water_tank',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.hot_water_tank',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.hot_water_tank',
    },
    [QualificationEquipmentType.Aquarium]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.aquarium',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.aquarium',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.aquarium.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.aquarium',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.aquarium',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.aquarium',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.aquarium',
    },
    [QualificationEquipmentType.Ventilation]: {
        tradKey: 'claim.qualification.leakage_cause_equipment.ventilation',
        leakageCauseEquipmentAnswerTradKey: 'claim.qualification.leakage_cause_equipment.sumup.ventilation',
        inSentenceWithVerbTradKey: 'claim.qualification.leakage_cause_equipment.ventilation.in_sentence.with_verb',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.ventilation',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.ventilation',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.ventilation',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.ventilation',
    },
};
export const QUALIFICATION_EQUIPMENT_DETAIL = {
    [LeakageCauseEquipmentDetail.AppliancePipe]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.appliance_pipe',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.appliance_pipe',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.appliance_pipe',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.appliance_pipe',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.appliance_pipe',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.appliance_pipe',
    },
    [LeakageCauseEquipmentDetail.ApplianceOverflow]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.appliance_overflow',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.appliance_overflow',
        equipmentAgeQuestionTradKey: null,
        equipmentAgeAnswerStartTradKey: null,
        outsiderInterventionQuestionTradKey: null,
        outsiderInterventionAnswerEndTradKey: null,
    },
    [LeakageCauseEquipmentDetail.ApplianceTap]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.appliance_tap',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.appliance_tap',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.appliance_tap',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.appliance_tap',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.appliance_tap',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.appliance_tap',
    },
    [LeakageCauseEquipmentDetail.ApplianceDrain]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.appliance_drain',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.appliance_drain',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.appliance_drain',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.appliance_drain',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.appliance_drain',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.appliance_drain',
    },
    [LeakageCauseEquipmentDetail.BoilerCollective]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.boiler_collective',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.boiler_collective',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.boiler_collective',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.boiler_collective',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.boiler_collective',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.boiler_collective',
    },
    [LeakageCauseEquipmentDetail.BoilerIndividual]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.boiler_individual',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.boiler_individual',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.boiler_individual',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.boiler_individual',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.boiler_individual',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.boiler_individual',
    },
    [LeakageCauseEquipmentDetail.BoilerBalloon]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.boiler_balloon',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.boiler_balloon',
        equipmentAgeQuestionTradKey: null,
        equipmentAgeAnswerStartTradKey: null,
        outsiderInterventionQuestionTradKey: null,
        outsiderInterventionAnswerEndTradKey: null,
    },
    [LeakageCauseEquipmentDetail.BoilerPipe]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.boiler_pipe',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.boiler_pipe',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.boiler_pipe',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.boiler_pipe',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.boiler_pipe',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.boiler_pipe',
    },
    [LeakageCauseEquipmentDetail.PipeAccessible]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.pipe_accessible',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.pipe_accessible',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.pipe_accessible',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.pipe_accessible',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.pipe_accessible',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.pipe_accessible',
    },
    [LeakageCauseEquipmentDetail.PipeTap]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.pipe_tap',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.pipe_tap',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.pipe_tap',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.pipe_tap',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.pipe_tap',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.pipe_tap',
    },
    [LeakageCauseEquipmentDetail.PipeNotAccessible]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.pipe_not_accessible',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.pipe_not_accessible',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.pipe_not_accessible',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.pipe_not_accessible',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.pipe_not_accessible',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.pipe_not_accessible',
    },
    [LeakageCauseEquipmentDetail.WashBasinSeal]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wash_basin_seal',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wash_basin_seal',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wash_basin_seal',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wash_basin_seal',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wash_basin_seal',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wash_basin_seal',
    },
    [LeakageCauseEquipmentDetail.WashBasinShowerHose]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wash_basin_shower_hose',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wash_basin_shower_hose',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wash_basin_shower_hose',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wash_basin_shower_hose',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wash_basin_shower_hose',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wash_basin_shower_hose',
    },
    [LeakageCauseEquipmentDetail.WashBasinDrain]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wash_basin_drain',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wash_basin_drain',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wash_basin_drain',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wash_basin_drain',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wash_basin_drain',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wash_basin_drain',
    },
    [LeakageCauseEquipmentDetail.WashBasinTap]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wash_basin_tap',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wash_basin_tap',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wash_basin_tap',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wash_basin_tap',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wash_basin_tap',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wash_basin_tap',
    },
    [LeakageCauseEquipmentDetail.WashBasinWaterSupply]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wash_basin_water_supply',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wash_basin_water_supply',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wash_basin_water_supply',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wash_basin_water_supply',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wash_basin_water_supply',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wash_basin_water_supply',
    },
    [LeakageCauseEquipmentDetail.WcTap]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wc_tap',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wc_tap',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wc_tap',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wc_tap',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wc_tap',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wc_tap',
    },
    [LeakageCauseEquipmentDetail.WcFlusher]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wc_flusher',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wc_flusher',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wc_flusher',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wc_flusher',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wc_flusher',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wc_flusher',
    },
    [LeakageCauseEquipmentDetail.WcCommonWaterDisposal]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wc_common_water_disposal',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wc_common_water_disposal',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wc_common_water_disposal',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wc_common_water_disposal',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wc_common_water_disposal',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wc_common_water_disposal',
    },
    [LeakageCauseEquipmentDetail.WcBreak]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wc_break',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wc_break',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wc_break',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wc_break',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wc_break',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wc_break',
    },
    [LeakageCauseEquipmentDetail.WcIndividualWaterDisposal]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.wc_individual_water_disposal',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.wc_individual_water_disposal',
        equipmentAgeQuestionTradKey: 'claim.qualification.equipment_age.title.wc_individual_water_disposal',
        equipmentAgeAnswerStartTradKey: 'claim.qualification.equipment_age.sumup.wc_individual_water_disposal',
        outsiderInterventionQuestionTradKey: 'claim.qualification.outsider_intervention.title.wc_individual_water_disposal',
        outsiderInterventionAnswerEndTradKey: 'claim.qualification.outsider_intervention.sumup.wc_individual_water_disposal',
    },
    [LeakageCauseEquipmentDetail.Spillage]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.spillage',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.spillage',
        equipmentAgeQuestionTradKey: null,
        equipmentAgeAnswerStartTradKey: null,
        outsiderInterventionQuestionTradKey: null,
        outsiderInterventionAnswerEndTradKey: null,
    },
    [LeakageCauseEquipmentDetail.Unknown]: {
        tradKey: 'claim.qualification.leakage_cause_equipment_detail.unknown',
        leakageCauseEquipmentDetailAnswerTradKey: 'claim.qualification.leakage_cause_equipment_detail.sumup.unknown',
        equipmentAgeQuestionTradKey: null,
        equipmentAgeAnswerStartTradKey: null,
        outsiderInterventionQuestionTradKey: null,
        outsiderInterventionAnswerEndTradKey: null,
    },
};
export const QUALIFICATION_COMMON_AREA_DETAIL = {
    [LeakageCauseCommonAreaDetail.CommonAreaAccessiblePipe]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.accessible_pipe',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.accessible_pipe',
    },
    [LeakageCauseCommonAreaDetail.CommonAreaNonAccessiblePipe]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.non_accessible_pipe',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.non_accessible_pipe',
    },
    [LeakageCauseCommonAreaDetail.CommonAreaClogging]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.clogging',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.clogging',
    },
    [LeakageCauseCommonAreaDetail.CommonAreaUnkwnown]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.unknown',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.unknown',
    },
    [LeakageCauseCommonAreaDetail.CommonAreaRoofInfiltration]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.roof_infiltration',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.roof_infiltration',
    },
    [LeakageCauseCommonAreaDetail.CommonAreaFacadeInfiltration]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.facade_infiltration',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.facade_infiltration',
    },
    [LeakageCauseCommonAreaDetail.CommonAreaBuriedPipe]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.burried_pipe',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.burried_pipe',
    },
    [LeakageCauseCommonAreaDetail.CommonAreaGutter]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.gutter',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.gutter',
    },
    [LeakageCauseCommonAreaDetail.CommonAreaConduitFlux]: {
        tradKey: 'claim.qualification.leakage_cause_common_area_detail.conduit_flux',
        leakageCauseCommonAreaDetailAnswerTradKey: 'claim.qualification.leakage_cause_common_area_detail.sumup.conduit_flux',
    },
};
export const DEFAULT_ROOM_CONFIGURATION = {
    svgComponentName: '',
    equipments: [
        QualificationEquipmentType.Pipe,
        QualificationEquipmentType.WashBasin,
        QualificationEquipmentType.Sink,
        QualificationEquipmentType.Shower,
        QualificationEquipmentType.Bathtube,
        QualificationEquipmentType.WC,
        QualificationEquipmentType.Dishwasher,
        QualificationEquipmentType.Refrigerator,
        QualificationEquipmentType.WashingMachine,
        QualificationEquipmentType.Radiator,
        QualificationEquipmentType.Boiler,
        QualificationEquipmentType.HotWaterTank,
        QualificationEquipmentType.Aquarium,
        QualificationEquipmentType.Ventilation,
    ],
    otherEquipments: [],
};
export const ROOM_CONFIGURATION_BY_ROOM_TYPE = {
    [QualificationRoomType.Kitchen]: {
        svgComponentName: 'RoomKitchen',
        equipments: [
            QualificationEquipmentType.Sink,
            QualificationEquipmentType.Dishwasher,
            QualificationEquipmentType.Refrigerator,
            QualificationEquipmentType.WashingMachine,
            QualificationEquipmentType.Radiator,
        ],
        otherEquipments: [
            QualificationEquipmentType.Pipe,
            QualificationEquipmentType.Boiler,
            QualificationEquipmentType.HotWaterTank,
            QualificationEquipmentType.Aquarium,
            QualificationEquipmentType.Ventilation,
        ],
    },
    [QualificationRoomType.Lounge]: {
        svgComponentName: 'RoomLounge',
        equipments: [
            QualificationEquipmentType.Pipe,
            QualificationEquipmentType.Radiator,
            QualificationEquipmentType.Aquarium,
        ],
        otherEquipments: [
            QualificationEquipmentType.Refrigerator,
            QualificationEquipmentType.Ventilation,
        ],
    },
    [QualificationRoomType.Bathroom]: {
        svgComponentName: 'RoomBathroom',
        equipments: [
            QualificationEquipmentType.Pipe,
            QualificationEquipmentType.WashBasin,
            QualificationEquipmentType.Shower,
            QualificationEquipmentType.Bathtube,
            QualificationEquipmentType.Radiator,
            QualificationEquipmentType.HotWaterTank,
            QualificationEquipmentType.WashingMachine,
            QualificationEquipmentType.Ventilation,
        ],
        otherEquipments: [
            QualificationEquipmentType.WC,
            QualificationEquipmentType.Boiler,
            QualificationEquipmentType.Aquarium,
        ],
    },
    [QualificationRoomType.WC]: {
        svgComponentName: 'RoomWC',
        equipments: [
            QualificationEquipmentType.Pipe,
            QualificationEquipmentType.WashBasin,
            QualificationEquipmentType.WC,
            QualificationEquipmentType.Radiator,
            QualificationEquipmentType.Ventilation,
        ],
        otherEquipments: [
            QualificationEquipmentType.WashingMachine,
            QualificationEquipmentType.Boiler,
            QualificationEquipmentType.HotWaterTank,
            QualificationEquipmentType.Aquarium,
        ],
    },
    [QualificationRoomType.BedroomOffice]: {
        svgComponentName: 'RoomBedroomOffice',
        equipments: [QualificationEquipmentType.Radiator, QualificationEquipmentType.Ventilation],
        otherEquipments: [
            QualificationEquipmentType.Pipe,
            QualificationEquipmentType.WashBasin,
            QualificationEquipmentType.Boiler,
            QualificationEquipmentType.HotWaterTank,
            QualificationEquipmentType.Aquarium,
        ],
    },
    [QualificationRoomType.CorridorEntrance]: {
        svgComponentName: 'RoomCorridorEntrance',
        equipments: [QualificationEquipmentType.Pipe, QualificationEquipmentType.Radiator],
        otherEquipments: [
            QualificationEquipmentType.Boiler,
            QualificationEquipmentType.HotWaterTank,
            QualificationEquipmentType.Aquarium,
            QualificationEquipmentType.Ventilation,
        ],
    },
    [QualificationRoomType.Balcony]: DEFAULT_ROOM_CONFIGURATION,
    [QualificationRoomType.Basement]: {
        svgComponentName: 'RoomBasement',
        equipments: [QualificationEquipmentType.Pipe],
        otherEquipments: [
            QualificationEquipmentType.Dishwasher,
            QualificationEquipmentType.Refrigerator,
            QualificationEquipmentType.WashingMachine,
            QualificationEquipmentType.Boiler,
            QualificationEquipmentType.HotWaterTank,
            QualificationEquipmentType.Ventilation,
        ],
    },
    [QualificationRoomType.Garage]: {
        svgComponentName: 'RoomGarage',
        equipments: [
            QualificationEquipmentType.Pipe,
            QualificationEquipmentType.Boiler,
            QualificationEquipmentType.Ventilation,
        ],
        otherEquipments: [
            QualificationEquipmentType.Dishwasher,
            QualificationEquipmentType.Refrigerator,
            QualificationEquipmentType.WashingMachine,
            QualificationEquipmentType.WashBasin,
            QualificationEquipmentType.HotWaterTank,
        ],
    },
    [QualificationRoomType.Laundry]: {
        svgComponentName: 'RoomLaundry',
        equipments: [
            QualificationEquipmentType.Pipe,
            QualificationEquipmentType.Sink,
            QualificationEquipmentType.WashingMachine,
            QualificationEquipmentType.Boiler,
            QualificationEquipmentType.Ventilation,
        ],
        otherEquipments: [
            QualificationEquipmentType.Dishwasher,
            QualificationEquipmentType.Refrigerator,
            QualificationEquipmentType.Radiator,
            QualificationEquipmentType.HotWaterTank,
        ],
    },
    [QualificationRoomType.Other]: {
        svgComponentName: 'RoomOther',
        equipments: [],
        otherEquipments: [],
    },
};
export const QUALIFICATION_OUTSIDE_HOUSE_DETAIL = {
    [LeakageCauseOutsideHouseDetail.OutsideHouseGardenGround]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.garden_ground',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.garden_ground',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseBalconyTerrace]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.balcony_terrace',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.balcony_terrace',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseGarage]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.garage',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.garage',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseAccessiblePipe]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.accessible_pipe',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.accessible_pipe',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseClogging]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.clogging',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.clogging',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseGutter]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.gutter',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.gutter',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseFacadeInfiltration]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.facade_infiltration',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.facade_infiltration',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseRoofInfiltration]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.roof_infiltration',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.roof_infiltration',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseConduitFlux]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.conduit_flux',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.conduit_flux',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseSwimmingpool]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.swimmingpool',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.swimmingpool',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseBuriedPipe]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.buried_pipe',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.buried_pipe',
    },
    [LeakageCauseOutsideHouseDetail.OutsideHouseUnkwnown]: {
        tradKey: 'claim.qualification.leakage_cause_outside_house_detail.unkwnown',
        leakageCauseOutsideHouseDetailAnswerTradKey: 'claim.qualification.leakage_cause_outside_house_detail.sumup.unkwnown',
    },
};
export const DEFAULT_EQUIPMENT_CONFIGURATION = {
    svgComponentName: '',
    positionOfElementsInViewBox: [],
    equipmentDetails: [
        LeakageCauseEquipmentDetail.AppliancePipe,
        LeakageCauseEquipmentDetail.ApplianceOverflow,
        LeakageCauseEquipmentDetail.ApplianceTap,
        LeakageCauseEquipmentDetail.ApplianceDrain,
        LeakageCauseEquipmentDetail.BoilerCollective,
        LeakageCauseEquipmentDetail.BoilerIndividual,
        LeakageCauseEquipmentDetail.BoilerBalloon,
        LeakageCauseEquipmentDetail.BoilerPipe,
        LeakageCauseEquipmentDetail.PipeAccessible,
        LeakageCauseEquipmentDetail.PipeTap,
        LeakageCauseEquipmentDetail.PipeNotAccessible,
        LeakageCauseEquipmentDetail.WashBasinSeal,
        LeakageCauseEquipmentDetail.WashBasinShowerHose,
        LeakageCauseEquipmentDetail.WashBasinDrain,
        LeakageCauseEquipmentDetail.WashBasinTap,
        LeakageCauseEquipmentDetail.WcTap,
        LeakageCauseEquipmentDetail.WcFlusher,
        LeakageCauseEquipmentDetail.WcCommonWaterDisposal,
        LeakageCauseEquipmentDetail.WcBreak,
        LeakageCauseEquipmentDetail.WcIndividualWaterDisposal,
        LeakageCauseEquipmentDetail.Spillage,
        LeakageCauseEquipmentDetail.Unknown,
    ],
};
// TODO For WC case: WcTap is not mapped in backend, so its removed for now. We can let the other constant with WcTap
// cause they are not called when component render. Add WcTap in EQUIPMENT_CONFIGURATION_BY_EQUIPMENT_TYPE when its mapped in backend
// Delete this comment when it is done
export const EQUIPMENT_CONFIGURATION_BY_EQUIPMENT_TYPE = {
    [QualificationEquipmentType.Pipe]: {
        svgComponentName: '',
        positionOfElementsInViewBox: [],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.PipeAccessible,
            LeakageCauseEquipmentDetail.PipeNotAccessible,
            LeakageCauseEquipmentDetail.PipeTap,
        ],
    },
    [QualificationEquipmentType.WashBasin]: {
        svgComponentName: 'EquipmentWashBasin',
        positionOfElementsInViewBox: [
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinDrain,
                viewBoxX: 270,
                viewBoxY: 495,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinSeal,
                viewBoxX: 36,
                viewBoxY: 169,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinTap,
                viewBoxX: 386,
                viewBoxY: 70,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinWaterSupply,
                viewBoxX: 475,
                viewBoxY: 458,
            },
        ],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.WashBasinDrain,
            LeakageCauseEquipmentDetail.WashBasinSeal,
            LeakageCauseEquipmentDetail.WashBasinTap,
            LeakageCauseEquipmentDetail.WashBasinWaterSupply,
        ],
    },
    [QualificationEquipmentType.Sink]: {
        svgComponentName: 'EquipmentSink',
        positionOfElementsInViewBox: [
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinDrain,
                viewBoxX: 354,
                viewBoxY: 571,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinSeal,
                viewBoxX: 792,
                viewBoxY: 284,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinTap,
                viewBoxX: 470,
                viewBoxY: 90,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinWaterSupply,
                viewBoxX: 587,
                viewBoxY: 563,
            },
        ],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.WashBasinDrain,
            LeakageCauseEquipmentDetail.WashBasinSeal,
            LeakageCauseEquipmentDetail.WashBasinTap,
            LeakageCauseEquipmentDetail.WashBasinWaterSupply,
        ],
    },
    [QualificationEquipmentType.Shower]: {
        svgComponentName: 'EquipmentShower',
        positionOfElementsInViewBox: [
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinDrain,
                viewBoxX: 598,
                viewBoxY: 1065,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinTap,
                viewBoxX: 652,
                viewBoxY: 679,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinShowerHose,
                viewBoxX: 588,
                viewBoxY: 381,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinSeal,
                viewBoxX: 305,
                viewBoxY: 1060,
            },
        ],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.WashBasinDrain,
            LeakageCauseEquipmentDetail.WashBasinSeal,
            LeakageCauseEquipmentDetail.WashBasinShowerHose,
            LeakageCauseEquipmentDetail.WashBasinTap,
        ],
    },
    [QualificationEquipmentType.Bathtube]: {
        svgComponentName: 'EquipmentBathtube',
        positionOfElementsInViewBox: [
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinDrain,
                viewBoxX: 583,
                viewBoxY: 656,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinTap,
                viewBoxX: 82,
                viewBoxY: 587,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinShowerHose,
                viewBoxX: 565,
                viewBoxY: 172,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WashBasinSeal,
                viewBoxX: 246,
                viewBoxY: 340,
            },
        ],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.WashBasinDrain,
            LeakageCauseEquipmentDetail.WashBasinSeal,
            LeakageCauseEquipmentDetail.WashBasinShowerHose,
            LeakageCauseEquipmentDetail.WashBasinTap,
        ],
    },
    [QualificationEquipmentType.WC]: {
        svgComponentName: 'EquipmentWC',
        positionOfElementsInViewBox: [
            {
                equipment: LeakageCauseEquipmentDetail.WcBreak,
                viewBoxX: 205,
                viewBoxY: 590,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WcFlusher,
                viewBoxX: 442,
                viewBoxY: 77,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WcTap,
                viewBoxX: 184,
                viewBoxY: 53,
            },
            {
                equipment: LeakageCauseEquipmentDetail.WcIndividualWaterDisposal,
                viewBoxX: 605,
                viewBoxY: 654,
            },
        ],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.WcBreak,
            LeakageCauseEquipmentDetail.WcFlusher,
            LeakageCauseEquipmentDetail.WcCommonWaterDisposal,
            LeakageCauseEquipmentDetail.WcIndividualWaterDisposal,
        ],
    },
    [QualificationEquipmentType.Dishwasher]: {
        svgComponentName: 'EquipmentDishwasher',
        positionOfElementsInViewBox: [
            {
                equipment: LeakageCauseEquipmentDetail.AppliancePipe,
                viewBoxX: 933,
                viewBoxY: 461,
            },
            {
                equipment: LeakageCauseEquipmentDetail.ApplianceOverflow,
                viewBoxX: 611,
                viewBoxY: 685,
            },
            {
                equipment: LeakageCauseEquipmentDetail.ApplianceTap,
                viewBoxX: 62,
                viewBoxY: 273,
            },
        ],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.AppliancePipe,
            LeakageCauseEquipmentDetail.ApplianceDrain,
            LeakageCauseEquipmentDetail.ApplianceOverflow,
            LeakageCauseEquipmentDetail.ApplianceTap,
        ],
    },
    [QualificationEquipmentType.Refrigerator]: {
        svgComponentName: 'EquipmentRefrigerator',
        positionOfElementsInViewBox: [
            {
                equipment: LeakageCauseEquipmentDetail.ApplianceOverflow,
                viewBoxX: 628,
                viewBoxY: 1260,
            },
            {
                equipment: LeakageCauseEquipmentDetail.ApplianceTap,
                viewBoxX: 94,
                viewBoxY: 854,
            },
        ],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.AppliancePipe,
            LeakageCauseEquipmentDetail.ApplianceDrain,
            LeakageCauseEquipmentDetail.ApplianceOverflow,
            LeakageCauseEquipmentDetail.ApplianceTap,
        ],
    },
    [QualificationEquipmentType.WashingMachine]: {
        svgComponentName: 'EquipmentWashingMachine',
        positionOfElementsInViewBox: [
            {
                equipment: LeakageCauseEquipmentDetail.AppliancePipe,
                viewBoxX: 915,
                viewBoxY: 510,
            },
            {
                equipment: LeakageCauseEquipmentDetail.ApplianceOverflow,
                viewBoxX: 623,
                viewBoxY: 653,
            },
            {
                equipment: LeakageCauseEquipmentDetail.ApplianceTap,
                viewBoxX: 65,
                viewBoxY: 270,
            },
            {
                equipment: LeakageCauseEquipmentDetail.ApplianceDrain,
                viewBoxX: 870,
                viewBoxY: 310,
            },
        ],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.AppliancePipe,
            LeakageCauseEquipmentDetail.ApplianceDrain,
            LeakageCauseEquipmentDetail.ApplianceOverflow,
            LeakageCauseEquipmentDetail.ApplianceTap,
        ],
    },
    [QualificationEquipmentType.Radiator]: {
        svgComponentName: 'EquipmentRadiator',
        positionOfElementsInViewBox: [],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.BoilerCollective,
            LeakageCauseEquipmentDetail.BoilerIndividual,
            LeakageCauseEquipmentDetail.BoilerPipe,
        ],
    },
    [QualificationEquipmentType.Boiler]: {
        svgComponentName: 'EquipmentBoiler',
        positionOfElementsInViewBox: [],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.BoilerPipe,
            LeakageCauseEquipmentDetail.BoilerBalloon,
        ],
    },
    [QualificationEquipmentType.HotWaterTank]: {
        svgComponentName: 'EquipmentHotWaterTank',
        positionOfElementsInViewBox: [],
        equipmentDetails: [
            LeakageCauseEquipmentDetail.BoilerPipe,
            LeakageCauseEquipmentDetail.BoilerBalloon,
        ],
    },
    [QualificationEquipmentType.Aquarium]: {
        svgComponentName: 'EquipmentAquarium',
        positionOfElementsInViewBox: [],
        equipmentDetails: [LeakageCauseEquipmentDetail.Spillage],
    },
    [QualificationEquipmentType.Ventilation]: DEFAULT_EQUIPMENT_CONFIGURATION,
};
