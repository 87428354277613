export var ApiEntityType;
(function (ApiEntityType) {
    ApiEntityType["Material"] = "Material";
    ApiEntityType["Payout"] = "Payout";
    ApiEntityType["ClaimType"] = "ClaimType";
    ApiEntityType["HousingType"] = "HousingType";
    ApiEntityType["MarketShare"] = "MarketShare";
    ApiEntityType["FenceType"] = "FenceType";
    ApiEntityType["ArtisanAppointment"] = "ArtisanAppointment";
    ApiEntityType["Appointment"] = "Appointment";
    ApiEntityType["AppliedAllowance"] = "AppliedAllowance";
    ApiEntityType["Settings"] = "Settings";
    ApiEntityType["GutterType"] = "GutterType";
    ApiEntityType["CoveringCategory"] = "CoveringCategory";
    ApiEntityType["UploadedAppFile"] = "UploadedAppFile";
    ApiEntityType["DroughtDamageQualification"] = "DroughtDamageQualification";
    ApiEntityType["PersonalPropertyCategoryObsolescence"] = "PersonalPropertyCategoryObsolescence";
    ApiEntityType["Counting"] = "Counting";
    ApiEntityType["Allowance"] = "Allowance";
    ApiEntityType["AllowanceCategoryProductLine"] = "AllowanceCategoryProductLine";
    ApiEntityType["Claim"] = "Claim";
    ApiEntityType["PersonalPropertyCategoryDamageProbability"] = "PersonalPropertyCategoryDamageProbability";
    ApiEntityType["InsuranceContract"] = "InsuranceContract";
    ApiEntityType["RefundDeductible"] = "RefundDeductible";
    ApiEntityType["SurfaceDamage"] = "SurfaceDamage";
    ApiEntityType["GoodDamage"] = "GoodDamage";
    ApiEntityType["WaterDamageQualification"] = "WaterDamageQualification";
    ApiEntityType["LittleGateType"] = "LittleGateType";
    ApiEntityType["InsuranceCompany"] = "InsuranceCompany";
    ApiEntityType["AllowanceUnit"] = "AllowanceUnit";
    ApiEntityType["ThirdParty"] = "ThirdParty";
    ApiEntityType["HourlyRate"] = "HourlyRate";
    ApiEntityType["ExcludedProperty"] = "ExcludedProperty";
    ApiEntityType["RefundObsolescenceRatioCeiling"] = "RefundObsolescenceRatioCeiling";
    ApiEntityType["SpecificContractCategoryConditions"] = "SpecificContractCategoryConditions";
    ApiEntityType["User"] = "User";
    ApiEntityType["StoneWorkDamage"] = "StoneWorkDamage";
    ApiEntityType["ExternalDamage"] = "ExternalDamage";
    ApiEntityType["TileDisplacementDamage"] = "TileDisplacementDamage";
    ApiEntityType["WindowDamage"] = "WindowDamage";
    ApiEntityType["GardenShelterDamage"] = "GardenShelterDamage";
    ApiEntityType["FrameworkDamage"] = "FrameworkDamage";
    ApiEntityType["LittleGateDamage"] = "LittleGateDamage";
    ApiEntityType["RoofHoleDamage"] = "RoofHoleDamage";
    ApiEntityType["AntennaDamage"] = "AntennaDamage";
    ApiEntityType["PergolaDamage"] = "PergolaDamage";
    ApiEntityType["ChimneyDamage"] = "ChimneyDamage";
    ApiEntityType["DoorDamage"] = "DoorDamage";
    ApiEntityType["OtherDamage"] = "OtherDamage";
    ApiEntityType["FenceDamage"] = "FenceDamage";
    ApiEntityType["PlantsDamage"] = "PlantsDamage";
    ApiEntityType["SwimpoolCoverDamage"] = "SwimpoolCoverDamage";
    ApiEntityType["ExternalPersonalPropertyDamage"] = "ExternalPersonalPropertyDamage";
    ApiEntityType["ShuttersDamage"] = "ShuttersDamage";
    ApiEntityType["RoofDamage"] = "RoofDamage";
    ApiEntityType["TerraceFloorDamage"] = "TerraceFloorDamage";
    ApiEntityType["VerandaDamage"] = "VerandaDamage";
    ApiEntityType["GutterDamage"] = "GutterDamage";
    ApiEntityType["AwningDamage"] = "AwningDamage";
    ApiEntityType["GarageDoorDamage"] = "GarageDoorDamage";
    ApiEntityType["GateDamage"] = "GateDamage";
    ApiEntityType["ShuttersType"] = "ShuttersType";
    ApiEntityType["SpecificDeductibleCondition"] = "SpecificDeductibleCondition";
    ApiEntityType["Receipt"] = "Receipt";
    ApiEntityType["FenceBreakInDamage"] = "FenceBreakInDamage";
    ApiEntityType["GateBreakInDamage"] = "GateBreakInDamage";
    ApiEntityType["LittleGateBreakInDamage"] = "LittleGateBreakInDamage";
    ApiEntityType["BreakInDamage"] = "BreakInDamage";
    ApiEntityType["SlidingBayWindowBreakInDamage"] = "SlidingBayWindowBreakInDamage";
    ApiEntityType["GarageDoorBreakInDamage"] = "GarageDoorBreakInDamage";
    ApiEntityType["WindowBreakInDamage"] = "WindowBreakInDamage";
    ApiEntityType["ShutterBreakInDamage"] = "ShutterBreakInDamage";
    ApiEntityType["DoorBreakInDamage"] = "DoorBreakInDamage";
    ApiEntityType["OtherBreakInDamage"] = "OtherBreakInDamage";
    ApiEntityType["FrenchWindowBreakInDamage"] = "FrenchWindowBreakInDamage";
    ApiEntityType["GlassDamage"] = "GlassDamage";
    ApiEntityType["AwningGlassDamage"] = "AwningGlassDamage";
    ApiEntityType["FixedBayWindowGlassDamage"] = "FixedBayWindowGlassDamage";
    ApiEntityType["FrenchWindowGlassDamage"] = "FrenchWindowGlassDamage";
    ApiEntityType["GlassWallGlassDamage"] = "GlassWallGlassDamage";
    ApiEntityType["GreenhouseGlassDamage"] = "GreenhouseGlassDamage";
    ApiEntityType["OtherGlassDamage"] = "OtherGlassDamage";
    ApiEntityType["PergolaGlassDamage"] = "PergolaGlassDamage";
    ApiEntityType["SlidingBayWindowGlassDamage"] = "SlidingBayWindowGlassDamage";
    ApiEntityType["SwimpoolCoverGlassDamage"] = "SwimpoolCoverGlassDamage";
    ApiEntityType["TransomGlassDamage"] = "TransomGlassDamage";
    ApiEntityType["VeluxGlassDamage"] = "VeluxGlassDamage";
    ApiEntityType["VerandaGlassDamage"] = "VerandaGlassDamage";
    ApiEntityType["WindowGlassDamage"] = "WindowGlassDamage";
    ApiEntityType["ElectricalDamage"] = "ElectricalDamage";
    ApiEntityType["AlarmElectricalDamage"] = "AlarmElectricalDamage";
    ApiEntityType["GarageDoorMotorizationElectricalDamage"] = "GarageDoorMotorizationElectricalDamage";
    ApiEntityType["GateMotorizationElectricalDamage"] = "GateMotorizationElectricalDamage";
    ApiEntityType["HeatPumpElectricalDamage"] = "HeatPumpElectricalDamage";
    ApiEntityType["IntercomElectricalDamage"] = "IntercomElectricalDamage";
    ApiEntityType["OtherElectricalDamage"] = "OtherElectricalDamage";
    ApiEntityType["PhotovoltaicPanelsElectricalDamage"] = "PhotovoltaicPanelsElectricalDamage";
    ApiEntityType["RollingShuttersMotorizationElectricalDamage"] = "RollingShuttersMotorizationElectricalDamage";
    ApiEntityType["SwimpoolElectricalDamage"] = "SwimpoolElectricalDamage";
    ApiEntityType["ContractCategoryConditions"] = "ContractCategoryConditions";
    ApiEntityType["Equipment"] = "Equipment";
    ApiEntityType["ConstructionJobCategory"] = "ConstructionJobCategory";
    ApiEntityType["SpecificObsolescenceRatioCeilingCondition"] = "SpecificObsolescenceRatioCeilingCondition";
    ApiEntityType["SpecificCeilingCondition"] = "SpecificCeilingCondition";
    ApiEntityType["ElectricalDamageQualification"] = "ElectricalDamageQualification";
    ApiEntityType["ProductLine"] = "ProductLine";
    ApiEntityType["Expert"] = "Expert";
    ApiEntityType["DamagesGroup"] = "DamagesGroup";
    ApiEntityType["AllowanceCategory"] = "AllowanceCategory";
    ApiEntityType["RenovationMethodDescription"] = "RenovationMethodDescription";
    ApiEntityType["TileDisplacementType"] = "TileDisplacementType";
    ApiEntityType["CoveriCoveringCategoryObsolescencengCategoryObsolescence"] = "CoveriCoveringCategoryObsolescencengCategoryObsolescence";
    ApiEntityType["HousingCapacity"] = "HousingCapacity";
    ApiEntityType["Department"] = "Department";
    ApiEntityType["GarageDoorType"] = "GarageDoorType";
    ApiEntityType["RoomDamage"] = "RoomDamage";
    ApiEntityType["AwningType"] = "AwningType";
    ApiEntityType["ExpertAppointment"] = "ExpertAppointment";
    ApiEntityType["RobberyDamageQualification"] = "RobberyDamageQualification";
    ApiEntityType["PersonalProperty"] = "PersonalProperty";
    ApiEntityType["UploadedAppFileCollection"] = "UploadedAppFileCollection";
    ApiEntityType["RefundCeiling"] = "RefundCeiling";
    ApiEntityType["StormSnowHailDamageQualification"] = "StormSnowHailDamageQualification";
    ApiEntityType["GateType"] = "GateType";
    ApiEntityType["SpecificOptionCondition"] = "SpecificOptionCondition";
    ApiEntityType["Contract"] = "Contract";
    ApiEntityType["SubscribedCapital"] = "SubscribedCapital";
    ApiEntityType["ApplicableContractDetails"] = "ApplicableContractDetails";
    ApiEntityType["PersonalPropertyReceipt"] = "PersonalPropertyReceipt";
    ApiEntityType["PersonalPropertyCategory"] = "PersonalPropertyCategory";
    ApiEntityType["HelpContent"] = "HelpContent";
    ApiEntityType["StepHelp"] = "StepHelp";
    ApiEntityType["ProductCategory"] = "ProductCategory";
    ApiEntityType["HeliosDeclaration"] = "HeliosDeclaration";
    ApiEntityType["Charge"] = "Charge";
    ApiEntityType["DocumentAnalyse"] = "DocumentAnalyse";
    ApiEntityType["DocumentAnalyseValue"] = "DocumentAnalyseValue";
    ApiEntityType["DocumentAnalyseGroup"] = "DocumentAnalyseGroup";
    ApiEntityType["DocumentCheck"] = "DocumentCheck";
    ApiEntityType["GlassBreakageDamageQualification"] = "GlassBreakageDamageQualification";
    ApiEntityType["FireDamageQualification"] = "FireDamageQualification";
    ApiEntityType["Cover"] = "Cover";
    ApiEntityType["Warning"] = "Warning";
    ApiEntityType["MessageTemplate"] = "MessageTemplate";
    ApiEntityType["VerandaDamagePanel"] = "VerandaDamagePanel";
})(ApiEntityType || (ApiEntityType = {}));
