export const CLAIMS_PER_PAGE = 30;
export const CLAIMS_FILTERS_MAPPING = {
    caseNumber: 'caseNumber',
    from: 'createdAt[after]',
    to: 'createdAt[before]',
    firstName: 'contract.contact.firstName',
    lastName: 'contract.contact.lastName',
    contract: 'contract.number',
    status: 'status',
    type: 'type.trigram',
};
export const CLAIMS_SORTING_MAPPING = {
    caseNumber: 'order[caseNumber]',
    type: 'order[type.name]',
    createdAt: 'order[createdAt]',
    contract: 'order[contract.number]',
    name: 'order[contract.contact.lastName]',
    status: 'order[status]',
    realEstateDamagesCount: 'order[realEstateDamagesCount]',
    personalPropertyDamagesCount: 'order[personalPropertyDamagesCount]',
};
