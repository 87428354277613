import { QualificationRoomType } from '@shared/types/api/claim';
export const QUALIFICATION_ROOM = {
    [QualificationRoomType.Kitchen]: {
        tradKey: 'claim.qualification.leakage_cause_room.kitchen',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.kitchen',
        roomTitle: 'claim.declaration.room_damages.modal.title.kitchen',
    },
    [QualificationRoomType.Lounge]: {
        tradKey: 'claim.qualification.leakage_cause_room.lounge',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.lounge',
        roomTitle: 'claim.declaration.room_damages.modal.title.lounge',
    },
    [QualificationRoomType.Bathroom]: {
        tradKey: 'claim.qualification.leakage_cause_room.bathroom',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.bathroom',
        roomTitle: 'claim.declaration.room_damages.modal.title.bathroom',
    },
    [QualificationRoomType.WC]: {
        tradKey: 'claim.qualification.leakage_cause_room.wc',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.wc',
        roomTitle: 'claim.declaration.room_damages.modal.title.wc',
    },
    [QualificationRoomType.BedroomOffice]: {
        tradKey: 'claim.qualification.leakage_cause_room.bedroom_office',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.bedroom_office',
        roomTitle: 'claim.declaration.room_damages.modal.title.bedroom_office',
    },
    [QualificationRoomType.Basement]: {
        tradKey: 'claim.qualification.leakage_cause_room.basement',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.basement',
        roomTitle: 'claim.declaration.room_damages.modal.title.basement',
    },
    [QualificationRoomType.Balcony]: {
        tradKey: 'claim.qualification.leakage_cause_room.balcony',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.balcony',
        roomTitle: 'claim.declaration.room_damages.modal.title.balcony',
    },
    [QualificationRoomType.Garage]: {
        tradKey: 'claim.qualification.leakage_cause_room.garage',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.garage',
        roomTitle: 'claim.declaration.room_damages.modal.title.garage',
    },
    [QualificationRoomType.Laundry]: {
        tradKey: 'claim.qualification.leakage_cause_room.laundry',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.laundry',
        roomTitle: 'claim.declaration.room_damages.modal.title.laundry',
    },
    [QualificationRoomType.CorridorEntrance]: {
        tradKey: 'claim.qualification.leakage_cause_room.corridor_entrance',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.corridor_entrance',
        roomTitle: 'claim.declaration.room_damages.modal.title.corridor_entrance',
    },
    [QualificationRoomType.Other]: {
        tradKey: 'claim.qualification.leakage_cause_room.other',
        leakageCauseRoomAnswerTradKey: 'claim.qualification.leakage_cause_room.sumup.other',
        roomTitle: 'claim.declaration.room_damages.modal.title.other',
    },
};
export function coveringCategoryLabelsFromInfo(info) {
    if (!info || !info.coveringCategoriesByIri) {
        return {};
    }
    return Object.fromEntries(Object.entries(info.coveringCategoriesByIri).map(([id, { label }]) => [id, label]));
}
export function roomMaxCount(slug) {
    if (slug === QualificationRoomType.BedroomOffice) {
        return 5;
    }
    else {
        return 2;
    }
}
