import { GOOD_DAMAGE_CATEGORY_LABELS, GOOD_DAMAGE_TYPE_LABELS } from '@shared/roomDamage/goodDamage';
import { translatableObjectToChoices } from '@shared/choice';
import { ModelFieldInputType, ModelFieldsProjection, } from '@shared/modelField/modelField';
import { QUALIFICATION_ROOM } from '@shared/roomDamage/constants';
import { surfaceDamageOfType, } from '@shared/roomDamage/surfaceDamage';
import { surfaceDamageFields, } from '@shared/roomDamage/surfaceDamageFields';
import { ApiEntityType } from '@shared/types/api/apiEntityType';
import { SurfaceType } from '@shared/types/api/roomDamage';
import { ClaimFileType } from '@shared/types/file';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { formatOptionalSurface } from '@shared/utils/formatSurface';
import { optionalResourceIri } from '@shared/utils/iri';
import { isNotNull } from '@shared/utils/isNotNull';
import { removeArrayItem } from '@shared/utils/removeArrayItem';
import { EMPTY } from '@shared/utils/strings';
import { optionalString, optionalTranslatableEnumTransform } from '@shared/utils/transform';
import roomDamageConstraints from '@shared/validator/constraints/roomDamageConstraints';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
import { goodDamageFields } from '@shared/roomDamage/goodDamageFields';
import { QualificationRoomType } from '@shared/types/api/claim';
export function roomDamageTitle(model, translate) {
    return optionalTranslatableEnumTransform(model.roomNameSlug, QUALIFICATION_ROOM, translate, 'Pièce endommagée');
}
function surfaceDamagesFromModel(model) {
    const allSurfaceDamages = model.surfaceDamages;
    if (!Array.isArray(allSurfaceDamages)) {
        return [];
    }
    return allSurfaceDamages.filter((damage) => !!damage && typeof damage !== 'string');
}
/* function goodDamagesFromModel(
  model: ResourcePayload<RoomDamage>,
): ResourcePayload<GoodDamage>[] {
  const allGoodDamages: (ResourcePayload<GoodDamage> | string | null)[] | null | undefined =
    model.goodDamages
  if (!Array.isArray(allGoodDamages)) {
    return []
  }

  return allGoodDamages.filter(
    (damage): damage is ResourcePayload<GoodDamage> => !!damage && typeof damage !== 'string',
  )
} */
const surfacesLabels = {
    [SurfaceType.Floor]: 'sol',
    [SurfaceType.Wall]: 'mur',
    [SurfaceType.Ceiling]: 'plafond',
};
export function roomDamageSurfacesCountDescription(model) {
    const surfacesCount = {
        [SurfaceType.Wall]: 0,
        [SurfaceType.Floor]: 0,
        [SurfaceType.Ceiling]: 0,
    };
    for (const surfaceDamage of surfaceDamagesFromModel(model)) {
        if (!surfaceDamage.type) {
            continue;
        }
        if (surfaceDamage.type in surfacesCount) {
            surfacesCount[surfaceDamage.type]++;
        }
    }
    return Object.entries(surfacesCount)
        .filter(([_label, count]) => count > 0)
        .map(([label, count]) => {
        if (count > 1) {
            return `${count} ${surfacesLabels[label]}s`;
        }
        return surfacesLabels[label];
    })
        .join(', ')
        .toLowerCase();
}
function surfaceDamageDescriptionFields(model, { coveringCategoryLabels, translate }) {
    const allSurfaceDamages = model.surfaceDamages;
    if (!Array.isArray(allSurfaceDamages) || allSurfaceDamages.length === 0) {
        return [
            {
                key: 'surfaceDamages',
                label: translate('model.room_damage.surface_damages'),
                value: translate('model.room_damage.surface_damages.empty'),
            },
        ];
    }
    const filteredSurfaceDamages = allSurfaceDamages.filter((damage) => !!damage && typeof damage !== 'string');
    const fields = [];
    Object.values(SurfaceType).forEach((surfaceType) => {
        const surfaceDamages = filteredSurfaceDamages.filter(surfaceDamageOfType(surfaceType));
        if (surfaceDamages.length > 0) {
            const countAndLabels = {};
            surfaceDamages.forEach((surfaceDamage) => {
                const coveringCategoryIri = optionalResourceIri(surfaceDamage.coveringCategory);
                if (!coveringCategoryIri || !(coveringCategoryIri in coveringCategoryLabels)) {
                    return;
                }
                const label = coveringCategoryLabels[coveringCategoryIri];
                if (!(coveringCategoryIri in countAndLabels)) {
                    countAndLabels[coveringCategoryIri] = { label, count: 1 };
                }
                else {
                    countAndLabels[coveringCategoryIri].count++;
                }
            });
            let detail = '';
            Object.values(countAndLabels).forEach((category, index) => {
                detail += `${index > 0 ? ', ' : ''}${category.count} x ${category.label}`;
            });
            fields.push({
                key: `surfaceDamages.${surfaceType}`,
                label: translate(`claim.sumup.real_estate.${surfaceType}`),
                value: detail,
            });
        }
    });
    return fields;
}
function goodDamageDescriptionFields(model, { translate }) {
    const allGoodDamages = model.goodDamages;
    if (!Array.isArray(allGoodDamages) || allGoodDamages.length === 0) {
        return [
            {
                key: 'goodDamages',
                label: translate('model.room_damage.good_damages'),
                value: translate('model.room_damage.good_damages.empty'),
            },
        ];
    }
    const fields = [];
    const filteredGoodDamages = allGoodDamages.filter((damage) => !!damage && typeof damage !== 'string');
    const countAndLabels = {};
    filteredGoodDamages.forEach((goodDamage) => {
        var _a;
        if (!goodDamage || !goodDamage.category)
            return;
        const key = (_a = goodDamage.type) !== null && _a !== void 0 ? _a : goodDamage.category;
        const label = translate(goodDamage.type
            ? GOOD_DAMAGE_TYPE_LABELS[goodDamage.type]
            : GOOD_DAMAGE_CATEGORY_LABELS[goodDamage.category]);
        if (!(key in countAndLabels)) {
            countAndLabels[key] = { label, count: 1 };
        }
        else {
            countAndLabels[key].count++;
        }
    });
    let detail = '';
    Object.values(countAndLabels).forEach((category, index) => {
        detail += `${index > 0 ? ', ' : ''}${category.count} x ${category.label}`;
    });
    fields.push({
        key: `surfaceDamages`,
        label: translate(`claim.sumup.real_estate.good`),
        value: detail,
    });
    return fields;
}
const surfaceDamagesOrIriFromModel = (model) => { var _a; return [...((_a = model === null || model === void 0 ? void 0 : model.surfaceDamages) !== null && _a !== void 0 ? _a : [])].filter(isNotNull); };
const goodDamagesOrIriFromModel = (model) => { var _a; return [...((_a = model === null || model === void 0 ? void 0 : model.goodDamages) !== null && _a !== void 0 ? _a : [])].filter(isNotNull); };
export const roomDamageFields = (model, context) => {
    var _a, _b, _c;
    const { translate, edition, projection, type } = context;
    const title = roomDamageTitle(model, translate);
    const fields = [];
    const hasGoodDamages = claimTypeHasFeature(type, ClaimTypeFeatureFlag.DescribeRoomDamagedGoods);
    if (edition) {
        fields.push({
            label: translate('model.room_damage.room'),
            value: model.roomNameSlug,
            displayedValue: optionalTranslatableEnumTransform(model.roomNameSlug, QUALIFICATION_ROOM, translate),
            editable: {
                input: {
                    type: ModelFieldInputType.Select,
                    choices: translatableObjectToChoices(QUALIFICATION_ROOM, translate),
                },
                modelKey: 'roomNameSlug',
            },
        });
    }
    if (model.roomNameSlug !== QualificationRoomType.Other) {
        fields.push({
            label: translate('model.room_damage.surface'),
            value: model.surface,
            displayedValue: formatOptionalSurface(model.surface),
            editable: {
                input: {
                    type: ModelFieldInputType.Number,
                },
                unit: 'm²',
                modelKey: 'surface',
            },
        });
        const modelToSurfaceDamageFields = (model) => Array.isArray(model.surfaceDamages)
            ? model.surfaceDamages.map((surfaceDamage, index) => {
                const surfaceDamageContext = { ...context, index };
                return surfaceDamageFields(surfaceDamage, surfaceDamageContext);
            })
            : [
                surfaceDamageFields({ surface: null, type: null, age: null, coveringCategory: null }, { ...context, index: 0 }),
            ];
        const modelToGoodDamageFields = (model) => Array.isArray(model.goodDamages)
            ? model.goodDamages.map((goodDamage, index) => {
                const goodDamageContext = {
                    ...context,
                    index,
                };
                return goodDamageFields({ ...goodDamage, room: model.roomNameSlug }, goodDamageContext);
            })
            : [
                goodDamageFields({ room: model.roomNameSlug, category: null, type: null }, { ...context, index: 0 }),
            ];
        if (edition || projection === ModelFieldsProjection.Backoffice) {
            const surfaceDamagesCollection = {
                label: translate('model.room_damage.surface_damages'),
                modelFields: modelToSurfaceDamageFields(model),
                emptyString: translate('model.room_damage.surface_damages.empty'),
                addLabel: 'Ajouter une surface endommagée',
                editable: {
                    modelKey: 'surfaceDamages',
                    toPayload: (value, model, index) => {
                        const surfaceDamages = surfaceDamagesOrIriFromModel(model);
                        const current = surfaceDamages[index];
                        if (typeof current === 'string') {
                            surfaceDamages[index] = value;
                        }
                        else {
                            surfaceDamages[index] = { ...current, ...value };
                        }
                        return {
                            surfaceDamages,
                        };
                    },
                    add: (model) => {
                        const surfaceDamages = surfaceDamagesOrIriFromModel(model);
                        return {
                            surfaceDamages: [...surfaceDamages, { '@type': ApiEntityType.SurfaceDamage }],
                        };
                    },
                    delete: (model, index) => {
                        const surfaceDamages = surfaceDamagesOrIriFromModel(model);
                        return { surfaceDamages: removeArrayItem(surfaceDamages, index) };
                    },
                },
            };
            fields.push(surfaceDamagesCollection);
            if (hasGoodDamages) {
                const goodDamagesCollection = {
                    label: translate('model.room_damage.good_damages'),
                    modelFields: modelToGoodDamageFields(model),
                    emptyString: translate('model.room_damage.good_damages.empty'),
                    addLabel: 'Ajouter un bien endommagée',
                    editable: {
                        modelKey: 'goodDamages',
                        toPayload: (value, model, index) => {
                            const goodDamages = goodDamagesOrIriFromModel(model);
                            const current = goodDamages[index];
                            if (typeof current === 'string') {
                                goodDamages[index] = value;
                            }
                            else {
                                goodDamages[index] = { ...current, ...value };
                            }
                            return {
                                goodDamages,
                            };
                        },
                        add: (model) => {
                            const goodDamages = goodDamagesOrIriFromModel(model);
                            return {
                                goodDamages: [
                                    ...goodDamages,
                                    { '@type': ApiEntityType.GoodDamage, room: model.roomNameSlug },
                                ],
                            };
                        },
                        delete: (model, index) => {
                            const goodDamages = goodDamagesOrIriFromModel(model);
                            return { goodDamages: removeArrayItem(goodDamages, index) };
                        },
                    },
                };
                fields.push(goodDamagesCollection);
            }
        }
        else {
            fields.push(...surfaceDamageDescriptionFields(model, context));
            if (hasGoodDamages) {
                fields.push(...goodDamageDescriptionFields(model, context));
            }
        }
        if (projection !== ModelFieldsProjection.Pdf) {
            fields.push({
                label: translate('model.room_damage.pictures'),
                value: model.pictureCollection,
                displayedValue: {
                    type: 'fileCollection',
                    fileCollection: model.pictureCollection,
                    uploadLabel: translate('model.picture.upload'),
                    emptyLabel: translate('model.pictures.empty'),
                    fileType: ClaimFileType.RoomDamage,
                    displayAsCount: true,
                },
                editable: {
                    input: { type: ModelFieldInputType.FileCollection },
                    modelKey: 'pictureCollection',
                },
            });
            if (hasGoodDamages) {
                fields.push({
                    label: translate('model.room_damage.good_damages.pictures'),
                    value: model.goodDamagesPictureCollection,
                    displayedValue: {
                        type: 'fileCollection',
                        fileCollection: model.goodDamagesPictureCollection,
                        uploadLabel: translate('model.picture.upload'),
                        emptyLabel: translate('model.pictures.empty'),
                        fileType: ClaimFileType.RoomDamage,
                        displayAsCount: true,
                    },
                    editable: {
                        input: { type: ModelFieldInputType.FileCollection },
                        modelKey: 'goodDamagesPictureCollection',
                    },
                });
            }
        }
    }
    else {
        fields.push({
            label: translate('model.room_damage.room_label'),
            value: model.roomLabel,
            displayedValue: optionalString(model.roomLabel),
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'roomLabel',
            },
        }, {
            label: translate('model.room_damage.room_description'),
            value: model.roomDescription,
            displayedValue: optionalString(model.roomDescription),
            editable: {
                input: {
                    type: ModelFieldInputType.Text,
                },
                modelKey: 'roomDescription',
            },
        });
    }
    if (projection === ModelFieldsProjection.Backoffice && !edition) {
        const valuationAmount = (_c = (_b = (_a = model.refund) === null || _a === void 0 ? void 0 : _a.valuation) === null || _b === void 0 ? void 0 : _b.replacement) !== null && _c !== void 0 ? _c : null;
        const amountsCollection = {
            label: 'Montants',
            emptyString: EMPTY,
            addLabel: EMPTY,
            key: 'valuation',
            modelFields: [
                {
                    title: '',
                    fields: [
                        {
                            key: 'valuationAmount',
                            label: 'Dommages',
                            value: valuationAmount,
                            displayedValue: formatOptionalCentsAmount(valuationAmount),
                        },
                    ],
                    model: { '@type': null },
                },
            ],
        };
        fields.push(amountsCollection);
    }
    return {
        title,
        fields,
        model,
        editable: {
            constraints: roomDamageConstraints,
        },
    };
};
